import {Component} from '@angular/core'
import {IconComponent} from "@shared/components/icon.component"
import {MathjaxHtmlDirective} from "@shared/directives/mathjax-html.directive"
import {AppState} from "../../../../app.state"
import {InsetsDirective} from "@shared/directives/insets.directive"

@Component({
  imports: [IconComponent, MathjaxHtmlDirective, InsetsDirective],
  standalone: true,
  templateUrl: './questions.component.html'
})
export class StatisticQuestionListComponent {
  constructor(public state: AppState) { }
}
