import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router'
import {ProfileService} from "@core/services/profile.service"
import {catchError, of, switchMap, tap} from 'rxjs'
import {rnLog} from "@core/utils/rn-webview"
import {AppService} from "@core/services/app.service"


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private appService: AppService,
    private userService: ProfileService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (!this.userService.user) {
      rnLog(`[AuthGuard] No user data found, fetching the data for route: ${route}`)
      const params = route.queryParams

      const getUser = this.userService.getUser().pipe(
        tap(user => this.appService.setUser(user)),
        switchMap(() => {
          rnLog(`[AuthGuard] Fetched user successfully for route: ${route}`)
          return of(true)
        }),
        catchError(() => {
          rnLog('[AuthGuard] Couldn\'t fetch user.')
          return of(false)
        }),
      )

      if ('username' in params && 'password' in params) {
        return this.appService.login(`+${params.username}`, params.password).pipe(
          switchMap(() => getUser),
          catchError(() => {
            rnLog('[AuthGuard] Couldn\'t login user.')
            return of(false)
          })
        )
      }

      return getUser
    }

    rnLog(`[AuthGuard] Has auth tokens and user data, entering route: ${route}`)
    return true
  }
}
