import {Component} from '@angular/core'
import {opacityAnimation} from "@core/animations/opacity"
import {MathjaxHtmlDirective} from "@shared/directives/mathjax-html.directive"
import {CommonModule} from "@angular/common"
import {firstValueFrom, Subscription} from "rxjs"
import {TimerService} from "@core/services/ui/timer.service"
import {startTimerAnimation} from "@core/animations/start-timer"
import {circleExpandAnimation} from "@core/animations/circle-expand"
import {ActivatedRoute, Router} from "@angular/router"
import {QuizService} from "@quiz"

@Component({
  standalone: true,
  imports: [CommonModule, MathjaxHtmlDirective],
  animations: [opacityAnimation, startTimerAnimation, circleExpandAnimation],
  templateUrl: './start.component.html'
})
export class StartTimerComponent {
  show: boolean = false
  time: number = 0
  guid: string

  private timerSubscription: Subscription

  constructor(
    private timerService: TimerService,
    private activatedRoute: ActivatedRoute,
    private quizService: QuizService,
    private router: Router
  ) {
    this.timerService.startTimer()
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(({guid}) => this.guid = guid)
    this.timerSubscription = this.timerService.timer$.subscribe(async (time) => {
      this.time = time
      if (this.time <= 0) {
        await firstValueFrom(this.quizService.playerStartQuiz(this.guid))
        this.router.navigate(['/', this.guid, 'test'])
      }
    })
  }

  ngOnDestroy() {
    if (this.timerSubscription) this.timerSubscription.unsubscribe()
  }
}
