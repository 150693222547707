<question-countdown></question-countdown>

@if (state.question) {

  @if (answered) {
    <div insets [marginTop]="true" [marginBottom]="true" [@routeAnimations]="state.question.id" class="relative flex flex-col items-center gap-5 w-full p-4 mx-auto pt-[72px] pb-[84px]">
      <div class="bg-[#F0F2FD] flex flex-col gap-4 justify-between items-start p-4 rounded-xl w-full max-w-xl">
        <div class="p-1 w-7 h-7 rounded-full bg-white shadow-lg">
          <mat-icon class="text-[20px] leading-5 text-primary-700">lightbulb</mat-icon>
        </div>
        <div [innerMathjaxHTML]="state.question.body"></div>
      </div>
    </div>
  } @else {
    <div insets [marginTop]="true" [marginBottom]="true" [@routeAnimations]="state.question.id" class="relative flex flex-col items-center gap-5 w-full p-4 mx-auto pt-[72px] pb-[84px]">

      <div #question class="relative overflow-hidden w-full max-w-xl">
        <div class="bg-[#807DFF] flex flex-col justify-between items-start p-4 rounded-lg text-white min-h-[280px]">
          <div class="bg-[rgba(0,0,0,0.12)] px-[6px] rounded-full text-white">
            {{state.question.index + 1}} / {{state.questions.length}}
          </div>
          <div [innerMathjaxHTML]="state.question.body"></div>
        </div>
        @for (size of [72, 128, 190, 260, 332, 406, 482, 560]; track size){
          <div
            class="absolute left-[50%] top-[50%] transform -translate-x-1/2 -translate-y-1/2 border border-[rgb(255,255,255,0.1)] rounded-full pointer-events-none"
            [style.width.px]="size"
            [style.height.px]="size"
          ></div>
        }
      </div>

      @for (answer of state.question.answers; track answer.id){
        <div
          @opacityAnimation
          class="border rounded-[13px] max-w-xl w-full"
          [class.border-transparent]="selected !== answer.id"
          [class.border-primary-700]="selected === answer.id"
        >
          <div
            class="px-3 py-4 bg-primary-50 border text-primary-400 rounded-xl cursor-pointer w-full"
            [class.border-primary-100]="selected !== answer.id"
            [class.border-primary-700]="selected === answer.id"
            [innerMathjaxHTML]="answer.body"
            (click)="onSelect(answer)"
          ></div>
        </div>
      }

    </div>

    <div insets [marginBottom]="true" [@opacityAnimation]="state.question" class="fixed bottom-0 left-0 right-0 rounded-t-xl shadow-top">
      <div class="flex justify-center flex-row py-3 px-4 bg-white">
        <button
          [disabled]="!selected || answered"
          (click)="answerQuestion(state.question.id)"
          class="w-full flex gap-1 items-center justify-center text-center bg-primary-700 hover:bg-primary-700 disabled:opacity-70 text-white py-3 px-4 rounded-xl text-base transition-all max-w-xl"
        >
          Ответить
        </button>
      </div>
    </div>
  }
}
