<div insets [marginBottom]="true" class="flex flex-col gap-3">
  @for (question of state.questions; track question.id; let index = $index) {
    <div class="p-4 border rounded-xl border-[#EBEBEC]">

      <div class="flex justify-between items-start mb-4">
        <div class="flex justify-center items-center rounded-lg bg-[#147FEC] p-2 w-[26px] h-[26px] text-white">
          {{index + 1}}
        </div>
        <div
          class="flex items-center gap-2 text-white text-[12px] rounded-full py-1 px-2"
          [class]="{
            'bg-[#f9c358]': state.questionStatistics[question.id] <= 79,
            'bg-[#4CCE17]': state.questionStatistics[question.id] <= 100,
          }"
        >
          {{state.questionStatistics[question.id]}}%
        </div>
      </div>

      <div class="mb-4" [innerMathjaxHTML]="question.body"></div>

      <hr class="text-[#EBEBEC] m-0">

      <div class="flex flex-col gap-4 py-4">
        @for (answer of question.answers; track answer.id) {
          <div class="flex items-center gap-3">
            <div class="flex justify-center items-center w-4 h-4 rounded-full p-[2px]" [class]="{'bg-[#EC2222]': !answer.correct, 'bg-[#4CCE17]': answer.correct}">
              <mat-icon class="text-white text-xs font-semibold" [class]="">{{answer.correct ? 'check': 'close'}}</mat-icon>
            </div>
            <div class="" [innerMathjaxHTML]="answer.body"></div>
          </div>
        }
      </div>

    </div>
  }
</div>
