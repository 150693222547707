import {HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http'
import {HTTP_PARAM_FETCHING_MEDIA} from "@core/constants/constants"
import {AppService} from "@core/services/app.service"
import {environment} from "@env"
import {inject} from "@angular/core"
import {catchError, Observable, Subject, switchMap, take, tap, throwError} from 'rxjs'
import {rnWebviewPostMessage, rnWebviewPostMessageLog} from "@core/utils/rn-webview"
import moment from "moment"

let isRefreshingTokenInProgress = false
const onFinishRefreshingToken: Subject<boolean> = new Subject()
const onFinishRefreshingToken$: Observable<boolean> = onFinishRefreshingToken.asObservable()

const applyRequestHeaders = (request: HttpRequest<any>) => {
  const appService: AppService = inject(AppService)
  if (request.params.has(HTTP_PARAM_FETCHING_MEDIA)) return request

  const lang = appService.lang
  if (lang) {
    request = request.clone({
      setHeaders: {'Accept-Language': lang}
    })
  }

  if (request.url.startsWith(environment.api_base_url)) {
    const token = appService.token
    if (token) {
      return request.clone({
        setHeaders: {Authorization: `Bearer ${token}`}
      })
    }
  }

  return request
}

const isTokenExpired = () => {
  const appService: AppService = inject(AppService)

  const accessTokenExpireDate = appService.tokenExpireMoment
  const leftSeconds = moment().diff(accessTokenExpireDate, 'seconds')
  rnWebviewPostMessageLog(`Access token expiration left seconds: ${leftSeconds}}`)
  return leftSeconds > -35
}

const refreshToken = (request: HttpRequest<unknown>): Observable<unknown> => {
  const appService: AppService = inject(AppService)

  if (isRefreshingTokenInProgress) {
    rnWebviewPostMessageLog(`Waits for refresh: ${request.url}`)

    return onFinishRefreshingToken$.pipe(
      take(1),
      tap(() => rnWebviewPostMessageLog(`Finished waiting - re-launch request: ${request.url}`))
    )
  } else {
    rnWebviewPostMessage({event: 'on_refresh_token', data: request.url})
    rnWebviewPostMessageLog(`Try to refresh token: ${request.url}`)

    return appService.onMobileAppFinishRefreshingToken$
      .pipe(
        take(1),
        tap(() => {
          isRefreshingTokenInProgress = false
          onFinishRefreshingToken.next(false)

          rnWebviewPostMessageLog(`Successfully refreshed - update tokens: ${request.url}`)
        })
      )
  }
}

const launchRefreshToken = (request: HttpRequest<any>, next: HttpHandlerFn) => {
  return refreshToken(request)
    .pipe(
      switchMap(() => {
        request = applyRequestHeaders(request)
        return next(request)
      }),
      catchError(error => {
        return throwError(error)
      })
    )
}

export function authInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  request = applyRequestHeaders(request)
  // if (isTokenExpired()) return launchRefreshToken(request, next)
  return next(request)
}
