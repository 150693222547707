<div insets [marginTop]="true"class="fixed top-0 left-0 right-0 h-[60px] transition-all p-4 flex justify-center border-b border-[#F8F8F8] shadow-top bg-white">
  <div class="flex justify-between w-full max-w-xl">
    <mat-icon class="text-2xl cursor-pointer" routerLink="../">chevron_left</mat-icon>
    <mat-icon class="text-2xl cursor-pointer">more_vert</mat-icon>
  </div>
</div>

<div insets [marginTop]="true" [marginBottom]="true"  class="flex flex-col gap-3 px-4 md:px-0 pt-[76px] pb-[88px] max-w-xl mx-auto">
  @if (state.quiz) {
    @for (question of state.questions; track question.id; let index = $index) {
      <div class="p-4 border rounded-xl border-[#EBEBEC]">

        <div class="flex justify-between items-start mb-4">
          <div class="flex justify-center items-center rounded-lg bg-[#147FEC] p-2 w-[26px] h-[26px] text-white">
            {{index + 1}}
          </div>
          <div class="flex items-center gap-2 text-[#323246] text-[12px]">
            <div class="flex gap-1 items-center bg-[#F7F7F9] px-2 py-1 rounded-full">
              <mat-icon class="text-sm">schedule</mat-icon>
              <span>{{question.time | duration:'m:ss сек'}}</span>
            </div>
            <div class="flex gap-1 items-center bg-[#F7F7F9] px-2 py-1 rounded-full">
              <mat-icon class="text-sm">task_alt</mat-icon>
              <span>{{question.points}} очков</span>
            </div>
          </div>
        </div>

        <div class="mb-4" [innerMathjaxHTML]="question.body"></div>

        <div class="flex flex-col my-4">
          <hr class="text-[#EBEBEC] m-0">
          <div class="flex flex-col gap-4 py-4">
            @for (answer of question.answers; track answer.id) {
              <div class="flex items-center gap-3">
                <div class="flex justify-center items-center w-4 h-4 rounded-full p-[2px]" [class]="{'bg-[#EC2222]': !answer.correct, 'bg-[#4CCE17]': answer.correct}">
                  <mat-icon class="text-white text-xs font-semibold" [class]="">{{answer.correct ? 'check': 'close'}}</mat-icon>
                </div>
                <div [innerMathjaxHTML]="answer.body"></div>
              </div>
            }
          </div>
        </div>

      </div>
    }
  }
</div>
