<header-teacher></header-teacher>

<div insets [marginTop]="true" [marginBottom]="true" class="flex flex-col gap-3 px-4 md:px-0 pt-[76px] pb-[88px] max-w-xl mx-auto">

  <div class="flex gap-3 flex-col w-full mx-auto p-3 bg-[#EFEFFF] rounded-xl">
    <div class="flex gap-2 items-center ">
      <select
        [(value)]="mode"
        id="countries"
        class="h-12 border border-gray-300 text-gray-600 text-base rounded-lg block w-full py-2.5 px-4 focus:outline-none"
      >
        <option value="0">Синхронный квиз</option>
        <option value="1">Индвидуальный квиз</option>
      </select>
      <mat-icon class="cursor-pointer">info</mat-icon>
    </div>
    <button
      (click)="onActivate()"
      class="w-full flex gap-1 items-center justify-center text-center bg-primary-700 hover:bg-primary-700 disabled:opacity-70 text-white py-3 px-4 rounded-xl text-base transition-all max-w-xl mx-auto"
    >
      Отправить приглашение
    </button>
  </div>

  @for (attendance of attendances; track attendance.student_id; let index = $index) {
    <div class="flex flex-col gap-3">
      <div
        class="flex items-center gap-2 px-2 py-[10px] rounded-[10px] border border-[#EEE] bg-[#F3F8FF]"
        [class]="{
          'bg-white': kicked_users.includes(attendance.student.user_id) || attendance.status === 0,
          'bg-[#4CCE170F]': attendance.online,
          'bg-[#F3F8FF]': !attendance.online
        }"
      >
        <span class="w-5 h-5 flex items-center justify-center text-xs text-[#7A7A7A]">{{index+1}}</span>

        <div class="flex flex-1 border-r border-[#EBEBEB] gap-2 items-center">
          <avatar [class]="{'opacity-50': attendance.status === 0}"></avatar>
          <div class="flex flex-col gap-[2px]">
            <span class="text-[#05044B] font-semibold text-[13px]">{{attendance.student.last_name}} {{attendance.student.first_name}}</span>

            @if (kicked_users.includes(attendance.student.user_id)) {
              <div class="flex flex-row gap-1 items-center">
                <span class="w-2 h-2 rounded-full bg-[#EC2222]"></span>
                <span class="text-sm text-[#EC2222]">Исключен</span>
              </div>
            } @else if (attendance.status === 0) {
              <div class="flex flex-row gap-1 items-center">
                <span class="w-2 h-2 rounded-full bg-[#B5B5B5]"></span>
                <span class="text-sm text-[#B5B5B5]">Отсутствует</span>
              </div>
            }
          </div>
        </div>

        @if (kicked_users.includes(attendance.student.user_id) || absent_users.includes(attendance.student.user_id)) {
          <mat-icon
            class="flex justify-center items-center text-xl w-6 h-6 cursor-pointer text-[#147FEC] font-semibold"
            (click)="restorePlayer(attendance)"
          >add</mat-icon>
        } @else {
          <mat-icon
            class="flex justify-center items-center text-xl w-6 h-6 cursor-pointer font-semibold"
            (click)="kickPlayer(attendance)"
          >close</mat-icon>
        }

      </div>
    </div>
  }
</div>
