@if (state.question && state.quiz.status === 3) {
  <div insets [marginTop]="true" @opacityAnimation class="fixed top-0 left-0 right-0 h-[52px] transition-all flex flex-col justify-center z-10">
    <div class="flex justify-between w-full max-w-xl px-3 py-2 mx-auto z-10 bg-white">
      <div
        class="flex gap-1 items-center rounded-full px-2 py-1 transition-all"
        [class.bg-gray-100]="countdown > 10"
        [class.bg-red-500]="countdown <= 10"
        [class.outline]="countdown <= 10"
        [class.outline-4]="countdown <= 10"
        [class.outline-red-100]="countdown <= 10"
        [class.text-white]="countdown <= 10"
      >
        <mat-icon class="text-[18px]">timer</mat-icon>
        <span class="font-bold">{{countdown}}</span>
      </div>
      <div class="p-2">
        <mat-icon class="cursor-pointer">more_vert</mat-icon>
      </div>
    </div>

    <div class="relative w-full h-1 top-0">
      <div class="absolute left-0 right-0 top-0 w-full h-1 bg-primary-100 z-0"></div>
      <div class="absolute left-0 top-0 h-1 bg-primary-700 rounded-r z-10" [style.width.%]="100 * time / (duration * 100)"></div>
    </div>
  </div>
}
