import {AfterViewInit, Component, OnDestroy} from '@angular/core'
import {opacityAnimation} from "@core/animations/opacity"
import {TimerComponent} from "@core/timer/timer.component"
import {MathjaxHtmlDirective} from "@shared/directives/mathjax-html.directive"
import {TestQuestionAnswer} from "@quiz"
import {CommonModule} from "@angular/common"
import {IconComponent} from "@shared/components/icon.component"
import {AppState} from "../../../app.state"
import {QuestionCountdownComponent} from "../countdown/countdown.component"
import {firstValueFrom} from "rxjs"
import {slideInAnimation} from "@core/animations/slidein"
import {InsetsDirective} from "@shared/directives/insets.directive"

@Component({
  standalone: true,
  imports: [CommonModule, MathjaxHtmlDirective, TimerComponent, IconComponent, QuestionCountdownComponent, InsetsDirective],
  animations: [opacityAnimation, slideInAnimation],
  templateUrl: './question.component.html'
})
export class QuestionComponent implements AfterViewInit, OnDestroy {
  sizes = []
  selected: number
  started_at: Date = new Date()
  answered: boolean = false

  constructor(public state: AppState) { }

  ngAfterViewInit(): void {
    this.state.test$.subscribe(() => this.ngOnDestroy())
  }

  async answerQuestion(questionId: number) {
    this.answered = true
    const finished_at = new Date()
    await firstValueFrom(this.state.answerQuestion(questionId, this.selected, this.started_at, finished_at))
  }

  onSelect(answer: TestQuestionAnswer) {
    if (this.selected === answer.id) {
      this.selected = undefined
      return
    }
    this.selected = answer.id
  }

  ngOnDestroy(): void {
    this.selected = null
    this.started_at = new Date()
    this.answered = false
  }
}
