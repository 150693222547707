import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router'
import {ProfileService} from "@core/services/profile.service"
import {rnLog} from "@core/utils/rn-webview"

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(
    private router: Router,
    private profileService: ProfileService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const {guid} = route.params
    if (!guid) {
      this.router.navigate(['/'])
      return false
    }

    const redirectPath = route.data['redirect'] ? `/${guid}/${route.data['redirect']}` : `/`
    const isTeacher = (route.data['isTeacher'] ?? false) as boolean
    const isStudent = (route.data['isStudent'] ?? false) as boolean

    let hasAccess: boolean = true

    if (isStudent) hasAccess = this.profileService.isStudent
    if (isTeacher) hasAccess = this.profileService.isTeacher

    if (!hasAccess) {
      rnLog('[RoleGuard] Access denied for route:')
      this.router.navigate([redirectPath])
      return true
    }

    rnLog('[RoleGuard] Has access to route:')
    return true
  }
}
