export type RnWebviewEventType =
  'on_load' |
  'on_navigate' |
  'on_navigate_mobile' |
  'on_refresh_token' |
  'refresh_token' |
  'log' |
  'alert' |
  'share' |
  'check_contacts_permission' |
  'update_contacts' |
  'request_contacts';

interface RnWebviewPayloadModel {
  event: RnWebviewEventType;
  data?: any;
}

export const rnWebviewPostMessage = (payload: RnWebviewPayloadModel): void => {
  window['ReactNativeWebView']?.postMessage(JSON.stringify(payload));
};

export const rnWebviewPostMessageOnLoad = (): void => {
  rnWebviewPostMessage({event: 'on_load'});
};

export const rnWebviewPostMessageOnNavigate = (data: any): void => {
  rnWebviewPostMessage({event: 'on_navigate', data});
};

export const rnWebviewPostMessageLog = (data: any): void => {
  rnWebviewPostMessage({event: 'log', data});
};

export const rnLog = (data: any): void => {
  rnWebviewPostMessage({event: 'log', data});
};

// export const rnWebviewPostMessageAlert = ({title, message}: DialogModel): void => {
//   rnWebviewPostMessage({
//     event: 'alert',
//     data: {title, message}
//   });
// };

export const rnWebviewPostMessageShare = (url: string): void => {
  rnWebviewPostMessage({
    event: 'share',
    data: {url}
  });
};

export const rnCheckContactsPermission = (): void => {
  rnWebviewPostMessage({event: 'check_contacts_permission'});
};

export const rnRequestContacts = (): void => {
  rnWebviewPostMessage({event: 'request_contacts'});
};

export const rnUpdateContacts = (): void => {
  rnWebviewPostMessage({event: 'update_contacts'});
};
