import {Component} from '@angular/core'
import {ActivatedRoute, NavigationStart, Router, RouterEvent, RouterOutlet} from '@angular/router'
import {filter} from "rxjs"
import {rnWebviewPostMessageOnNavigate} from "@core/utils/rn-webview"
import {AppState} from "./app.state"
import {AppService} from "@core/services/app.service"

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  providers: [AppState],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private app: AppService,
  ) {
    this.initNavigationChange()
  }

  initNavigationChange(): void {
    this.router.events
      .pipe(filter((e: any) => e instanceof RouterEvent && e instanceof NavigationStart))
      .subscribe(async ({url}: RouterEvent) => {
        let payload = {
          goBack: true,
          scrollEnabled: false,
          tabBarVisible: false,
          orientation: 'lockToPortrait',
        }
        switch (true) {
          case url === '/':
          case url.startsWith('/error'):
            payload = {...payload, goBack: false, tabBarVisible: true}
            break
        }

        this.activatedRoute.queryParams.subscribe(async (params) => {
          const newParams = {...params}
          if ('username' in params && 'password' in params) {
            delete newParams.username
            delete newParams.password
            window.location.replace(url.split('?')[0])
          }
        })

        rnWebviewPostMessageOnNavigate(payload)
      })
  }
}
