import {Component, OnDestroy} from '@angular/core'
import {opacityAnimation} from "@core/animations/opacity"
import {CommonModule} from "@angular/common"
import {IconComponent} from "@shared/components/icon.component"
import {AppState} from "../../../app.state"
import {Countdown} from "@core/utils/countdown"
import moment from "moment"
import {InsetsDirective} from "@shared/directives/insets.directive"

@Component({
  selector: 'question-countdown',
  standalone: true,
  imports: [CommonModule, IconComponent, InsetsDirective],
  animations: [opacityAnimation],
  templateUrl: './countdown.component.html'
})
export class QuestionCountdownComponent extends Countdown implements OnDestroy {
  progress: number = 0
  duration: number = 0

  constructor(
    public state: AppState
  ) {
    super()
    this.initCountdown()
  }

  ngOnDestroy(): void {
    this.stopCountdown()
  }

  initCountdown(): void {
    this.state.test$.subscribe((test) => {
      if (!test) return
      this.stopCountdown()
      const now = new Date()
      const timeLeft = moment.duration(moment(test.timer).diff(now), 'milliseconds').asSeconds().toFixed(0)
      this.duration = moment.duration(test.time).asSeconds()
      this.startCountdown(+timeLeft)
    })
  }

  onFinishCountdown(): void {
    this.stopCountdown()
  }
}
