import {Component} from '@angular/core'
import {IconComponent} from "@shared/components/icon.component"
import {AppState} from "../../../app.state"
import {MathjaxHtmlDirective} from "@shared/directives/mathjax-html.directive"
import {DurationPipe} from "@shared/pipes/duration.pipe"
import {QuizQuestionComponent} from "./question/question.component"
import {RouterLink} from "@angular/router"
import {InsetsDirective} from "@shared/directives/insets.directive"

@Component({
  standalone: true,
  imports: [RouterLink, IconComponent, MathjaxHtmlDirective, DurationPipe, QuizQuestionComponent, InsetsDirective],
  templateUrl: './quiz.component.html',
})
export class QuizComponent {
  constructor(public state: AppState) { }
}
