import moment from 'moment'

export abstract class Countdown {

  countdown: number
  countdownOutput: string

  time: number
  private interval: any

  abstract initCountdown(): void
  abstract onFinishCountdown(): void

  startCountdown(seconds: number): void {
    this.time = seconds * 100
    this.next()
    this.interval = setInterval(this.next, 10)
  }

  stopCountdown(): void {
    if (this.interval) clearInterval(this.interval)
  }

  private next = (): void => {
    if (this.time <= 0) {
      this.time = 0
      this.stopCountdown()
      this.onFinishCountdown()
    }

    const duration = moment.duration(this.time * 10, 'milliseconds')

    this.countdown = +duration.asSeconds().toFixed(0)
    this.countdownOutput = moment.utc(duration.asMilliseconds()).format('mm:ss')

    this.time--
  }
}

