import {Component, OnDestroy, OnInit} from '@angular/core'
import {ActivatedRoute, NavigationStart, Router, RouterEvent, RouterOutlet} from '@angular/router'
import {CentrifugoService} from "@core/services/centrifugo.service"
import {Quiz, QuizStatus, QuizTest} from "@quiz"
import {filter, firstValueFrom} from "rxjs"
import {AppService} from "@core/services/app.service"
import {rnWebviewPostMessageOnNavigate} from "@core/utils/rn-webview"
import {ProfileService} from "@core/services/profile.service"
import {AppState} from "../../app.state"

@Component({
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './main.component.html',
})
export class MainComponent implements OnDestroy, OnInit {
  guid: string
  quiz: Quiz
  test: QuizTest

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private centrifugoService: CentrifugoService,
    private profileService: ProfileService,
    private app: AppService,
    private state: AppState,
  ) {
    this.initNavigationChange()
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async ({guid}) => {
      this.guid = guid
      await firstValueFrom(this.state.getQuiz(guid))
      if (this.guid) {
        this.app.initialized$.subscribe(inited => {
          if (inited) this.centrifugoService.connect()
        })
      }
    })

    this.state.quiz$.subscribe(quiz => {
      if (!quiz) return
      if (!this.profileService.user) return

      if (this.profileService.isStudent) {
        if (quiz.status === QuizStatus.PUBLISHED) this.router.navigate([`/${quiz.guid}/waitlist`])
        if (quiz.status === QuizStatus.READY) this.router.navigate([`/${quiz.guid}/waitlist`])
        if (quiz.status === QuizStatus.LIVE) this.router.navigate([`/${quiz.guid}/test`])
        if (quiz.status === QuizStatus.FINISHED) this.router.navigate([`/${quiz.guid}/results`])
        return
      }
      if (this.profileService.isTeacher) {
        if (quiz.status === QuizStatus.PUBLISHED) this.router.navigate([`/${quiz.guid}/`])
        if (quiz.status === QuizStatus.READY) this.router.navigate([`/${quiz.guid}/start`])
        if (quiz.status === QuizStatus.LIVE) this.router.navigate([`/${quiz.guid}/statistics`])
        return
      }
    })
  }

  ngOnDestroy(): void {
    this.centrifugoService.unsubscribeQuiz(this.guid)
    this.centrifugoService.unsubscribePersonal()
    this.centrifugoService.disconnect()
  }

  initNavigationChange(): void {
    this.router.events
      .pipe(filter((e: any) => e instanceof RouterEvent && e instanceof NavigationStart),)
      .subscribe(({url}: RouterEvent) => {
        let payload = {
          goBack: true,
          scrollEnabled: false,
          tabBarVisible: false,
          orientation: 'lockToPortrait',
        }
        switch (true) {
          case url === '/':
          case url.startsWith('/error'):
            payload = {...payload, goBack: false, tabBarVisible: true}
            break
        }
        rnWebviewPostMessageOnNavigate(payload)
      })
  }
}
