import {Component} from '@angular/core'
import {AppState} from "../../../../app.state"
import {AvatarComponent} from "@shared/components/avatar.component"
import {InsetsDirective} from "@shared/directives/insets.directive"

@Component({
  imports: [AvatarComponent, InsetsDirective],
  standalone: true,
  templateUrl: './rating.component.html'
})
export class RatingComponent {
  constructor(public state: AppState) { }
}
