<div insets [marginTop]="true"class="fixed top-0 left-0 right-0 h-[60px] transition-all p-4 flex justify-center border-b border-[#F8F8F8] shadow-top bg-white">
  <div class="flex justify-between w-full max-w-xl">
    <mat-icon class="text-2xl cursor-pointer" routerLink="/">chevron_left</mat-icon>
    <mat-icon class="text-2xl cursor-pointer">more_vert</mat-icon>
  </div>
</div>

<div insets [marginTop]="true" [marginBottom]="true"  class="flex flex-col gap-3 px-4 md:px-0 pt-[76px] pb-[88px] max-w-xl mx-auto">
  @if (state.quiz) {
    <div class="flex w-full gap-3 items-start">

      <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 12.8C0 8.31958 0 6.07937 0.871948 4.36808C1.63893 2.86278 2.86278 1.63893 4.36808 0.871948C6.07937 0 8.31958 0 12.8 0H24.2C28.6804 0 30.9206 0 32.6319 0.871948C34.1372 1.63893 35.3611 2.86278 36.1281 4.36808C37 6.07937 37 8.31958 37 12.8V24.2C37 28.6804 37 30.9206 36.1281 32.6319C35.3611 34.1372 34.1372 35.3611 32.6319 36.1281C30.9206 37 28.6804 37 24.2 37H12.8C8.31958 37 6.07937 37 4.36808 36.1281C2.86278 35.3611 1.63893 34.1372 0.871948 32.6319C0 30.9206 0 28.6804 0 24.2V12.8Z" fill="#312EC6"/>
        <path d="M21.4758 30.2041C20.2494 30.2041 19.2846 30.0304 18.5812 29.683C17.8959 29.3539 17.418 28.8511 17.1475 28.1746C16.877 27.4982 16.7327 26.6571 16.7147 25.6515C14.4603 25.3773 12.747 24.518 11.5748 23.0736C10.4025 21.6109 9.81641 19.4992 9.81641 16.7384C9.81641 13.4657 10.5558 11.1163 12.0347 9.69018C13.5135 8.26408 15.6686 7.55103 18.5001 7.55103C21.3315 7.55103 23.4867 8.26408 24.9655 9.69018C26.4443 11.1163 27.1838 13.4657 27.1838 16.7384C27.1838 19.4443 26.6157 21.5195 25.4795 22.9639C24.3613 24.4083 22.7292 25.2859 20.5831 25.5967C20.5831 26.1818 20.6823 26.584 20.8807 26.8034C21.0971 27.0411 21.4578 27.1599 21.9627 27.1599C22.5218 27.1599 23.0899 27.0868 23.667 26.9405V29.9298C23.3965 30.003 23.0358 30.067 22.5849 30.1218C22.1521 30.1767 21.7824 30.2041 21.4758 30.2041ZM18.5001 22.004C19.9609 22.004 21.0249 21.5835 21.6922 20.7425C22.3775 19.8831 22.7202 18.5485 22.7202 16.7384C22.7202 14.7638 22.3775 13.3743 21.6922 12.5698C21.0249 11.7471 19.9609 11.3357 18.5001 11.3357C17.0212 11.3357 15.9392 11.7471 15.2538 12.5698C14.5866 13.3743 14.2529 14.7638 14.2529 16.7384C14.2529 18.5485 14.5866 19.8831 15.2538 20.7425C15.9392 21.5835 17.0212 22.004 18.5001 22.004Z" fill="white"/>
      </svg>

      <div class="flex flex-col gap-1">
        <span class="text-[#747474] text-[13px]">Квиз</span>
        <span class="text-black text-[15px]">{{state.quiz.name}}</span>
      </div>

    </div>

    <div class="flex flex-row items-center gap-4 p-3 rounded-xl bg-[#F8F8F8] text-[#222426] text-sm">
      <div class="flex gap-1 items-center">
        <mat-icon>list</mat-icon>
        <span>{{state.quiz.question_cnt}} вопросов</span>
      </div>
      <div class="flex gap-1 items-center">
        <mat-icon>school</mat-icon>
        <span>{{state.quiz.question_cnt}} вопросов</span>
      </div>
    </div>

    @for (question of state.questions; track question.id; let index = $index) {
      <question-view [index]="index + 1" [question]="question"></question-view>
    }
  }
</div>

<div insets [marginBottom]="true" class="fixed bottom-0 left-0 right-0 rounded-t-xl shadow-top bg-white py-3 px-4">
  @if (state.quiz && state.quiz.status != 4) {
    <button
      routerLink="activate"
      class="w-full flex gap-1 items-center justify-center text-center bg-[#147FEC] hover:bg-[#147FEC] disabled:opacity-70 text-white py-3 px-4 rounded-xl text-base transition-all max-w-xl mx-auto cursor-pointer"
    >
      Начать квиз
    </button>
  } @else if (state.quiz) {
    <div
      routerLink="leaderboard"
      class="w-full flex gap-1 items-center justify-center text-center bg-[#147FEC] hover:bg-[#147FEC] disabled:opacity-70 text-white py-3 px-4 rounded-xl text-base transition-all max-w-xl mx-auto cursor-pointer"
    >
      Посмотреть результаты
  </div>
  }
</div>
