<div insets [marginTop]="true" #header class="fixed top-0 left-0 right-0 h-[60px] transition-all p-4 flex justify-center z-40">
  <div class="flex justify-between w-full max-w-xl">

    <div class="flex gap-3 items-center">
      @if (state.quiz) {
        <mat-icon class="text-2xl cursor-pointer" routerLink="/{{state.quiz.guid}}/{{profileService.isStudent ? 'results' : ''}}">chevron_left</mat-icon>
      }
      <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 13.3C0 8.81958 0 6.57937 0.871948 4.86808C1.63893 3.36278 2.86278 2.13893 4.36808 1.37195C6.07937 0.5 8.31958 0.5 12.8 0.5H14.2C18.6804 0.5 20.9206 0.5 22.6319 1.37195C24.1372 2.13893 25.3611 3.36278 26.1281 4.86808C27 6.57937 27 8.81958 27 13.3V14.7C27 19.1804 27 21.4206 26.1281 23.1319C25.3611 24.6372 24.1372 25.8611 22.6319 26.6281C20.9206 27.5 18.6804 27.5 14.2 27.5H12.8C8.31958 27.5 6.07937 27.5 4.36808 26.6281C2.86278 25.8611 1.63893 24.6372 0.871948 23.1319C0 21.4206 0 19.1804 0 14.7V13.3Z" fill="#312EC6"/>
        <path d="M15.6723 22.5409C14.7774 22.5409 14.0733 22.4141 13.56 22.1606C13.0599 21.9205 12.7112 21.5536 12.5138 21.0599C12.3164 20.5663 12.2111 19.9525 12.1979 19.2187C10.5529 19.0186 9.30263 18.3915 8.4472 17.3375C7.59178 16.2702 7.16406 14.7292 7.16406 12.7146C7.16406 10.3264 7.70364 8.61192 8.78279 7.57126C9.86195 6.53059 11.4346 6.01025 13.5008 6.01025C15.567 6.01025 17.1396 6.53059 18.2188 7.57126C19.298 8.61192 19.8375 10.3264 19.8375 12.7146C19.8375 14.6892 19.423 16.2035 18.5939 17.2575C17.7779 18.3115 16.5869 18.9519 15.0208 19.1787C15.0208 19.6056 15.0932 19.8992 15.238 20.0593C15.3959 20.2327 15.6591 20.3194 16.0276 20.3194C16.4356 20.3194 16.8501 20.2661 17.2713 20.1593V22.3407C17.0738 22.3941 16.8106 22.4408 16.4816 22.4808C16.1658 22.5209 15.896 22.5409 15.6723 22.5409ZM13.5008 16.557C14.5668 16.557 15.3433 16.2502 15.8302 15.6364C16.3303 15.0094 16.5803 14.0354 16.5803 12.7146C16.5803 11.2736 16.3303 10.2596 15.8302 9.67261C15.3433 9.07222 14.5668 8.77203 13.5008 8.77203C12.4216 8.77203 11.632 9.07222 11.1319 9.67261C10.645 10.2596 10.4015 11.2736 10.4015 12.7146C10.4015 14.0354 10.645 15.0094 11.1319 15.6364C11.632 16.2502 12.4216 16.557 13.5008 16.557Z" fill="white"/>
      </svg>
    </div>

    <button routerLink="/" class="flex gap-1 items-center justify-center text-center bg-[#EC2222] hover:bg-[#EC2222] text-white py-2 px-4 rounded text-[14px] transition-all">
      <mat-icon class="text-sm">logout</mat-icon>
      <span class="font-normal">Выйти</span>
    </button>

  </div>
</div>

<div insets [marginTop]="true" [marginBottom]="true" class="relative flex flex-col pt-[76px] min-h-screen bg-leaderboard">

  <div class="flex flex-col items-stretch w-full gap-[14px] mb-14 max-w-xl mx-auto">

    <div @opacityAnimation class="py-5 px-3 font-bold text-2xl text-center text-primary-700 w-full">Көшбасшылар тақтасы</div>

    <div class="relative flex flex-row justify-around items-end">

      <div class="relative w-[95px] h-[180px]">
        <div @secondPlace class="flex flex-col items-center gap-3">
          @if (state.leaderboard[1]){
            <div class="relative w-[95px] h-[148px]">
              <svg width="95" height="148" viewBox="0 0 95 148" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g filter="url(#filter0_b_4075_5481)">
                  <ellipse cx="47.4825" cy="47.5598" rx="46.6312" ry="46.6306" fill="#D6D6F3" fill-opacity="0.52"/>
                  </g>
                  <path d="M69.1794 76.8394H54.7202L40.2609 106.842H54.7202L69.1794 76.8394Z" fill="#2925C1"/>
                  <rect x="30.1382" y="112.625" width="34.7023" height="34.7019" rx="17.3509" fill="#CECDD2"/>
                  <path d="M61.0493 129.977C61.0493 137.463 54.9803 143.532 47.4938 143.532C40.0072 143.532 33.9382 137.463 33.9382 129.977C33.9382 122.49 40.0072 116.421 47.4938 116.421C54.9803 116.421 61.0493 122.49 61.0493 129.977Z" stroke="#9B9B9D" stroke-width="1.08444"/>
                  <path d="M43.2961 136.301L42.652 134.7L46.6341 130.347C46.8553 130.1 47.096 129.82 47.3563 129.508C47.6166 129.196 47.8703 128.877 48.1176 128.551C48.3648 128.213 48.56 127.888 48.7032 127.575C48.8593 127.263 48.9374 126.977 48.9374 126.716C48.9374 126.456 48.8788 126.228 48.7617 126.033C48.6446 125.838 48.4754 125.688 48.2542 125.584C48.033 125.467 47.7532 125.409 47.4149 125.409C47.1155 125.409 46.8293 125.487 46.556 125.643C46.2827 125.786 46.0224 126.014 45.7752 126.326C45.5409 126.638 45.3197 127.022 45.1115 127.478L42.652 126.15C42.9253 125.396 43.3092 124.732 43.8037 124.159C44.3112 123.574 44.9033 123.125 45.58 122.812C46.2567 122.487 46.9789 122.324 47.7467 122.324C48.5535 122.338 49.2823 122.513 49.9329 122.852C50.5836 123.177 51.0976 123.639 51.475 124.237C51.8654 124.823 52.0606 125.513 52.0606 126.307C52.0606 126.58 52.0281 126.866 51.963 127.165C51.8979 127.465 51.8003 127.777 51.6702 128.102C51.5401 128.415 51.3644 128.753 51.1432 129.117C50.9349 129.469 50.6812 129.84 50.3819 130.23C50.0826 130.62 49.7312 131.037 49.3278 131.479L46.2241 134.856L45.8337 133.373H52.6657V136.301H43.2961Z" fill="#9B9B9D"/>
                  <rect x="40.9822" y="108.288" width="13.0133" height="6.5066" fill="#CECDD2"/>
                  <mask id="path-7-inside-1_4075_5481" fill="white">
                  <rect x="37.7263" y="103.95" width="19.52" height="6.5066" rx="1.08444"/>
                  </mask>
                  <rect x="37.7263" y="103.95" width="19.52" height="6.5066" rx="1.08444" stroke="#CECDD2" stroke-width="4.33776" mask="url(#path-7-inside-1_4075_5481)"/>
                  <path d="M25.8005 76.8394H40.2598L54.7191 106.842H40.2598L25.8005 76.8394Z" fill="#5552E8"/>
                  <defs>
                  <filter id="filter0_b_4075_5481" x="-22.6487" y="-22.5708" width="140.262" height="140.261" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="11.75"/>
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4075_5481"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4075_5481" result="shape"/>
                  </filter>
                  <filter id="filter1_b_4075_5481" x="7.35926" y="8.52015" width="80.249" height="80.2483" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.16888"/>
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4075_5481"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4075_5481" result="shape"/>
                  </filter>
                  <pattern id="pattern1_4075_5481" patternContentUnits="objectBoundingBox" width="1" height="1">
                  <use xlink:href="#image1_4075_5481" transform="scale(0.000976562)"/>
                  </pattern>
                  </defs>
              </svg>
              <avatar
                class="absolute left-[6px] top-[6px] min-w-[72px] rounded-full border-none"
                [width]="72"
                [height]="72"
                [src]="state.leaderboard[1]?.user?.avatar"
              ></avatar>
            </div>

            <div class="flex flex-col gap-[2px] font-semibold">
              <span class="text-sm text-black">{{state.leaderboard[1]?.user?.full_name || state.leaderboard[1]?.user_id}}</span>
            </div>
          }
        </div>
      </div>

      <div class="relative w-[114px] h-[210px]">
        <div @firstPlace class="flex flex-col items-center gap-3">
          @if (state.leaderboard[1]){
            <div class="relative w-[114px] h-[178px]">
              <svg width="114" height="178" viewBox="0 0 114 178" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g filter="url(#filter0_b_4075_5519)">
                <ellipse cx="56.9652" cy="56.4821" rx="56.4828" ry="56.4821" fill="#D6D6F3" fill-opacity="0.52"/>
                </g>
                <path d="M83.252 91.9478H65.7379L48.2239 128.289H65.7379L83.252 91.9478Z" fill="#2925C1"/>
                <rect x="35.9629" y="135.294" width="42.0337" height="42.0333" rx="21.0166" fill="#FFD34B"/>
                <path d="M73.4058 156.311C73.4058 165.379 66.0546 172.731 56.9864 172.731C47.9182 172.731 40.5669 165.379 40.5669 156.311C40.5669 147.243 47.9182 139.892 56.9864 139.892C66.0546 139.892 73.4058 147.243 73.4058 156.311Z" stroke="#FA922A" stroke-width="1.31355"/>
                <path d="M55.3491 163.644V150.214L56.2239 152.224L52.6773 153.903L51.9916 150.309L56.6968 148.016H59.2976V163.644H55.3491ZM52.3226 164.803V161.256H62.3949V164.803H52.3226Z" fill="#FA912C"/>
                <rect x="49.0957" y="130.041" width="15.7626" height="7.88123" fill="#FFD34B"/>
                <mask id="path-7-inside-1_4075_5519" fill="white">
                <rect x="45.1562" y="124.786" width="23.644" height="7.88123" rx="1.31355"/>
                </mask>
                <rect x="45.1562" y="124.786" width="23.644" height="7.88123" rx="1.31355" stroke="#FFD34B" stroke-width="5.25419" mask="url(#path-7-inside-1_4075_5519)"/>
                <path d="M30.709 91.9478H48.223L65.7371 128.289H48.223L30.709 91.9478Z" fill="#5552E8"/>
                <defs>
                <filter id="filter0_b_4075_5519" x="-23.0176" y="-23.5" width="159.966" height="159.964" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="11.75"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4075_5519"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4075_5519" result="shape"/>
                </filter>
                <filter id="filter1_b_4075_5519" x="8.37862" y="9.19503" width="97.203" height="97.2017" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.6271"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4075_5519"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4075_5519" result="shape"/>
                </filter>
                </defs>
              </svg>
              <avatar
                class="absolute left-[6px] top-[6px] min-w-[90px] rounded-full border-none"
                [width]="90"
                [height]="90"
                [src]="state.leaderboard[0]?.user?.avatar"
              ></avatar>
            </div>
            <div class="flex flex-col gap-[2px] font-semibold">
              <span class="text-sm text-black">{{state.leaderboard[0]?.user?.full_name || state.leaderboard[0]?.user_id}}</span>
            </div>
          }
        </div>
      </div>

      <div class="relative w-[95px] h-[180px]">
        <div @thirdPlace class="flex flex-col items-center gap-3">
          @if (state.leaderboard[2]) {
            <div class="relative w-[95px] h-[148px]">
              <svg width="95" height="148" viewBox="0 0 95 148" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g filter="url(#filter0_b_4075_5500)">
                <ellipse cx="47.4825" cy="47.5598" rx="46.6312" ry="46.6306" fill="#D6D6F3" fill-opacity="0.52"/>
                </g>
                <path d="M69.1814 76.8394H54.7221L40.2628 106.842H54.7221L69.1814 76.8394Z" fill="#2925C1"/>
                <rect x="30.1638" y="112.625" width="34.7023" height="34.7019" rx="17.3509" fill="#F79429"/>
                <path d="M61.0496 129.977C61.0496 137.463 54.9806 143.532 47.494 143.532C40.0075 143.532 33.9385 137.463 33.9385 129.977C33.9385 122.49 40.0075 116.421 47.494 116.421C54.9806 116.421 61.0496 122.49 61.0496 129.977Z" stroke="#D25016" stroke-width="1.08444"/>
                <path d="M47.3412 136.496C46.2351 136.496 45.2656 136.268 44.4328 135.813C43.6129 135.344 42.8647 134.544 42.188 133.412L44.5694 131.87C44.8557 132.325 45.1485 132.69 45.4478 132.963C45.7601 133.236 46.0789 133.438 46.4043 133.568C46.7426 133.685 47.094 133.744 47.4584 133.744C47.8227 133.744 48.1546 133.666 48.4539 133.509C48.7662 133.34 49.0134 133.106 49.1956 132.807C49.3908 132.507 49.4884 132.169 49.4884 131.792C49.4884 131.388 49.4038 131.05 49.2347 130.777C49.0785 130.49 48.8443 130.276 48.5319 130.132C48.2326 129.989 47.8683 129.918 47.4388 129.918C47.2436 129.918 47.0354 129.944 46.8142 129.996C46.593 130.035 46.3587 130.093 46.1115 130.172C45.8642 130.25 45.6105 130.354 45.3502 130.484L44.628 128.551L47.9463 124.94L48.8443 125.428L43.1444 125.311V122.52H51.538L52.1431 123.73L47.634 128.571L46.8532 127.653C46.9573 127.601 47.1265 127.556 47.3608 127.517C47.608 127.465 47.8422 127.439 48.0635 127.439C48.6751 127.439 49.2477 127.549 49.7812 127.771C50.3148 127.992 50.7898 128.298 51.2062 128.688C51.6226 129.078 51.9479 129.54 52.1822 130.074C52.4164 130.594 52.5335 131.154 52.5335 131.753C52.5335 132.664 52.2993 133.477 51.8308 134.193C51.3754 134.895 50.7507 135.455 49.9569 135.871C49.1761 136.288 48.3042 136.496 47.3412 136.496Z" fill="#D25016"/>
                <rect x="41.0076" y="108.288" width="13.0133" height="6.5066" fill="#F79429"/>
                <mask id="path-7-inside-1_4075_5500" fill="white">
                <rect x="37.7478" y="103.95" width="19.52" height="6.5066" rx="1.08444"/>
                </mask>
                <rect x="37.7478" y="103.95" width="19.52" height="6.5066" rx="1.08444" stroke="#F79429" stroke-width="4.33776" mask="url(#path-7-inside-1_4075_5500)"/>
                <path d="M25.8201 76.8394H40.2793L54.7386 106.842H40.2793L25.8201 76.8394Z" fill="#5552E8"/>
                <defs>
                <filter id="filter0_b_4075_5500" x="-22.6487" y="-22.5708" width="140.262" height="140.261" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="11.75"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4075_5500"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4075_5500" result="shape"/>
                </filter>
                <filter id="filter1_b_4075_5500" x="7.36512" y="8.52015" width="80.249" height="80.2483" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.16888"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4075_5500"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4075_5500" result="shape"/>
                </filter>
                <pattern id="pattern1_4075_5500" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlink:href="#image1_4075_5500" transform="scale(0.000976562)"/>
                </pattern>
                <pattern id="pattern2_4075_5500" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlink:href="#image2_4075_5500" transform="scale(0.000976562)"/>
                </pattern>
                <pattern id="pattern3_4075_5500" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlink:href="#image3_4075_5500" transform="scale(0.000976562)"/>
                </pattern>
                <pattern id="pattern4_4075_5500" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlink:href="#image2_4075_5500" transform="scale(0.000976562)"/>
                </pattern>
                <pattern id="pattern5_4075_5500" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlink:href="#image4_4075_5500" transform="scale(0.000976562)"/>
                </pattern>
                <pattern id="pattern6_4075_5500" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlink:href="#image5_4075_5500" transform="scale(0.000976562)"/>
                </pattern>
                </defs>
              </svg>
              <avatar
                class="absolute left-[6px] top-[6px] min-w-[72px] rounded-full border-none"
                [width]="72"
                [height]="72"
                [src]="state.leaderboard[2]?.user?.avatar"
              ></avatar>
            </div>
            <div class="flex flex-col gap-[2px] font-semibold">
              <span class="text-sm text-black">{{state.leaderboard[2]?.user?.full_name || state.leaderboard[2]?.user_id}}</span>
            </div>
          }
        </div>
    </div>

    </div>

  </div>

  <div @leaderboard class="flex flex-col flex-1 rounded-t-2xl border-t border-gray-200 px-4 py-5 bg-white z-10 max-w-xl mx-auto w-full">
    <div class="flex flex-col border border-gray-200 rounded-xl overflow-hidden">
      @for (player of state.leaderboard; track player.user_id; let index = $index) {
        <div class="relative flex py-2 px-4 border-gray-200 border-b last:border-none">
          @if (index <= 2) {
            <div
              class="absolute left-0 top-0 bottom-0 w-[6px]"
              [class]="{
                'bg-[#FFD34B]': index === 0,
                'bg-[#CECDD2]': index === 1,
                'bg-[#F79429]': index === 2
              }"
            ></div>
          }
          <div class="flex items-center gap-3 w-full">

            <span class="text-gray-500 w-5 text-center">{{index+1}}</span>

            <avatar [src]="player.user?.avatar" [alt]="player.user?.full_name"></avatar>

            <span class="text-bold flex-auto">{{player.user ? player.user.full_name : player.user_id}}</span>

            <span class="text-primary-400">{{player.points}}</span>

          </div>
        </div>
      }
    </div>
  </div>

</div>
