<question-countdown></question-countdown>

@if (state.question) {
  <div insets [marginTop]="true" [marginBottom]="true" [@opacityAnimation]="state.question" class="flex flex-col gap-5 w-full max-w-xl p-4 mx-auto pt-[72px] pb-[84px]">

    <div class="bg-[#F0F2FD] flex flex-col gap-4 justify-between items-start p-4 rounded-xl">
      <div class="p-1 w-7 h-7 rounded-full bg-white shadow-lg">
        <mat-icon class="text-[20px] leading-5 text-primary-700">lightbulb</mat-icon>
      </div>
      <div [innerMathjaxHTML]="state.question.body"></div>
    </div>

  </div>

  <div insets [marginBottom]="true" [@opacityAnimation]="state.question" [@opacityAnimation]="state.question" class="fixed bottom-0 left-0 right-0 rounded-t-xl shadow-top">
    <div class="flex justify-center flex-row py-3 px-4 bg-white">
      <button
        routerLink="/{{state.quiz.guid}}/test"
        class="w-full flex gap-1 items-center justify-center text-center bg-primary-700 hover:bg-primary-700 disabled:opacity-70 text-white py-3 px-4 rounded-xl text-base transition-all max-w-xl"
      >
        Далее
      </button>
    </div>
  </div>
}
