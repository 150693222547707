import {ApplicationConfig, importProvidersFrom, provideZoneChangeDetection} from '@angular/core'
import {provideRouter, withRouterConfig, withViewTransitions} from '@angular/router'

import {routes} from './app.routes'
import {BrowserAnimationsModule} from "@angular/platform-browser/animations"
import {HttpClient, provideHttpClient, withInterceptors} from "@angular/common/http"
import {authInterceptor} from "@core/interceptors/auth-v2.interceptor"
import {QuizService} from "@quiz"
import {environment} from "@env"
import {RoleGuard} from "@core/guard/role.guard"
import {AuthGuard} from "@core/guard/auth.guard"
import {AppService} from "@core/services/app.service"
import {MAT_DIALOG_DEFAULT_OPTIONS} from "@angular/material/dialog"
import {alertInterceptor} from "@core/interceptors/response.interceptor"
import {Dialog} from "@angular/cdk/dialog";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({eventCoalescing: true}),
    importProvidersFrom([BrowserAnimationsModule]),
    AppService,
    Dialog,
    provideHttpClient(withInterceptors([authInterceptor, alertInterceptor])),
    provideRouter(
      routes,
      withViewTransitions(),
      withRouterConfig({paramsInheritanceStrategy: 'always'})
    ),
    {
      provide: QuizService,
      useFactory: (http: HttpClient) => new QuizService(http, environment.api_hs_quiz_url),
      deps: [HttpClient]
    },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
    AuthGuard,
    RoleGuard, provideAnimationsAsync()
  ],
}
