<div insets [marginTop]="true" #header class="fixed top-0 left-0 right-0 h-[60px] bg-primary-700 transition-all p-4 flex justify-center z-40">
  <div class="flex justify-between w-full max-w-xl">

    <div class="flex items-center">
      <div [@show]="isScrolled ? 'hide': 'show'" class="flex items-center gap-4 h-full">
        <svg width="42" height="18" viewBox="0 0 42 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.18403 7.48977C3.48022 6.70396 3.96564 6.1146 4.64029 5.72169C5.3314 5.32879 6.09655 5.13233 6.93575 5.13233V8.66849C5.96491 8.55389 5.0928 8.75034 4.31942 9.25784C3.56249 9.76535 3.18403 10.6085 3.18403 11.7872V17.6562H0V5.3779H3.18403V7.48977Z" fill="white"/>
          <path d="M18.8547 16.1337C17.5876 17.3779 16.0409 18 14.2144 18C12.3879 18 10.8411 17.3779 9.57407 16.1337C8.3235 14.8731 7.69821 13.3342 7.69821 11.5171C7.69821 9.69986 8.3235 8.16917 9.57407 6.92497C10.8411 5.66439 12.3879 5.03411 14.2144 5.03411C16.0409 5.03411 17.5876 5.66439 18.8547 6.92497C20.1217 8.16917 20.7552 9.69986 20.7552 11.5171C20.7552 13.3342 20.1217 14.8731 18.8547 16.1337ZM11.8202 13.9482C12.4619 14.5866 13.26 14.9059 14.2144 14.9059C15.1688 14.9059 15.9668 14.5866 16.6086 13.9482C17.2503 13.3097 17.5712 12.4993 17.5712 11.5171C17.5712 10.5348 17.2503 9.72442 16.6086 9.08595C15.9668 8.44748 15.1688 8.12824 14.2144 8.12824C13.26 8.12824 12.4619 8.44748 11.8202 9.08595C11.1949 9.72442 10.8822 10.5348 10.8822 11.5171C10.8822 12.4993 11.1949 13.3097 11.8202 13.9482Z" fill="white"/>
          <path d="M30.1777 5.03411C31.8397 5.03411 33.2548 5.66439 34.4231 6.92497C35.6078 8.16917 36.2002 9.69986 36.2002 11.5171C36.2002 13.3342 35.6078 14.8731 34.4231 16.1337C33.2548 17.3779 31.8397 18 30.1777 18C28.4499 18 27.1171 17.4025 26.1792 16.2074V17.6562H22.9951V0.466576H26.1792V6.82674C27.1171 5.63165 28.4499 5.03411 30.1777 5.03411ZM27.1418 14.0218C27.7835 14.6603 28.598 14.9795 29.5853 14.9795C30.5726 14.9795 31.3871 14.6603 32.0289 14.0218C32.6871 13.367 33.0162 12.5321 33.0162 11.5171C33.0162 10.502 32.6871 9.67531 32.0289 9.03684C31.3871 8.38199 30.5726 8.05457 29.5853 8.05457C28.598 8.05457 27.7835 8.38199 27.1418 9.03684C26.5 9.67531 26.1792 10.502 26.1792 11.5171C26.1792 12.5321 26.5 13.367 27.1418 14.0218Z" fill="white"/>
          <path d="M40.0254 3.9045C39.4988 3.9045 39.0381 3.71624 38.6432 3.3397C38.2647 2.94679 38.0755 2.4884 38.0755 1.96453C38.0755 1.44066 38.2647 0.982265 38.6432 0.589359C39.0381 0.196453 39.4988 0 40.0254 0C40.5684 0 41.0292 0.196453 41.4076 0.589359C41.8025 0.982265 42 1.44066 42 1.96453C42 2.4884 41.8025 2.94679 41.4076 3.3397C41.0292 3.71624 40.5684 3.9045 40.0254 3.9045ZM38.4457 17.6562V5.3779H41.6298V17.6562H38.4457Z" fill="white"/>
        </svg>
        <div class="h-full w-[1px] bg-white"></div>
      </div>

      @if (isScrolled) {
        <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 13.3C0 8.81958 0 6.57937 0.871948 4.86808C1.63893 3.36278 2.86278 2.13893 4.36808 1.37195C6.07937 0.5 8.31958 0.5 12.8 0.5H14.2C18.6804 0.5 20.9206 0.5 22.6319 1.37195C24.1372 2.13893 25.3611 3.36278 26.1281 4.86808C27 6.57937 27 8.81958 27 13.3V14.7C27 19.1804 27 21.4206 26.1281 23.1319C25.3611 24.6372 24.1372 25.8611 22.6319 26.6281C20.9206 27.5 18.6804 27.5 14.2 27.5H12.8C8.31958 27.5 6.07937 27.5 4.36808 26.6281C2.86278 25.8611 1.63893 24.6372 0.871948 23.1319C0 21.4206 0 19.1804 0 14.7V13.3Z" fill="#312EC6"/>
          <path d="M15.6723 22.5409C14.7774 22.5409 14.0733 22.4141 13.56 22.1606C13.0599 21.9205 12.7112 21.5536 12.5138 21.0599C12.3164 20.5663 12.2111 19.9525 12.1979 19.2187C10.5529 19.0186 9.30263 18.3915 8.4472 17.3375C7.59178 16.2702 7.16406 14.7292 7.16406 12.7146C7.16406 10.3264 7.70364 8.61192 8.78279 7.57126C9.86195 6.53059 11.4346 6.01025 13.5008 6.01025C15.567 6.01025 17.1396 6.53059 18.2188 7.57126C19.298 8.61192 19.8375 10.3264 19.8375 12.7146C19.8375 14.6892 19.423 16.2035 18.5939 17.2575C17.7779 18.3115 16.5869 18.9519 15.0208 19.1787C15.0208 19.6056 15.0932 19.8992 15.238 20.0593C15.3959 20.2327 15.6591 20.3194 16.0276 20.3194C16.4356 20.3194 16.8501 20.2661 17.2713 20.1593V22.3407C17.0738 22.3941 16.8106 22.4408 16.4816 22.4808C16.1658 22.5209 15.896 22.5409 15.6723 22.5409ZM13.5008 16.557C14.5668 16.557 15.3433 16.2502 15.8302 15.6364C16.3303 15.0094 16.5803 14.0354 16.5803 12.7146C16.5803 11.2736 16.3303 10.2596 15.8302 9.67261C15.3433 9.07222 14.5668 8.77203 13.5008 8.77203C12.4216 8.77203 11.632 9.07222 11.1319 9.67261C10.645 10.2596 10.4015 11.2736 10.4015 12.7146C10.4015 14.0354 10.645 15.0094 11.1319 15.6364C11.632 16.2502 12.4216 16.557 13.5008 16.557Z" fill="white"/>
        </svg>
      } @else {
        <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 13.3C0 8.81958 0 6.57937 0.871948 4.86808C1.63893 3.36278 2.86278 2.13893 4.36808 1.37195C6.07937 0.5 8.31958 0.5 12.8 0.5H14.2C18.6804 0.5 20.9206 0.5 22.6319 1.37195C24.1372 2.13893 25.3611 3.36278 26.1281 4.86808C27 6.57937 27 8.81958 27 13.3V14.7C27 19.1804 27 21.4206 26.1281 23.1319C25.3611 24.6372 24.1372 25.8611 22.6319 26.6281C20.9206 27.5 18.6804 27.5 14.2 27.5H12.8C8.31958 27.5 6.07937 27.5 4.36808 26.6281C2.86278 25.8611 1.63893 24.6372 0.871948 23.1319C0 21.4206 0 19.1804 0 14.7V13.3Z" fill="white"/>
          <path d="M15.6723 22.5409C14.7774 22.5409 14.0733 22.4141 13.56 22.1606C13.0599 21.9205 12.7112 21.5536 12.5138 21.0599C12.3164 20.5663 12.2111 19.9525 12.1979 19.2187C10.5529 19.0186 9.30263 18.3915 8.4472 17.3375C7.59178 16.2702 7.16406 14.7292 7.16406 12.7146C7.16406 10.3264 7.70364 8.61192 8.78279 7.57126C9.86195 6.53059 11.4346 6.01025 13.5008 6.01025C15.567 6.01025 17.1396 6.53059 18.2188 7.57126C19.298 8.61192 19.8375 10.3264 19.8375 12.7146C19.8375 14.6892 19.423 16.2035 18.5939 17.2575C17.7779 18.3115 16.5869 18.9519 15.0208 19.1787C15.0208 19.6056 15.0932 19.8992 15.238 20.0593C15.3959 20.2327 15.6591 20.3194 16.0276 20.3194C16.4356 20.3194 16.8501 20.2661 17.2713 20.1593V22.3407C17.0738 22.3941 16.8106 22.4408 16.4816 22.4808C16.1658 22.5209 15.896 22.5409 15.6723 22.5409ZM13.5008 16.557C14.5668 16.557 15.3433 16.2502 15.8302 15.6364C16.3303 15.0094 16.5803 14.0354 16.5803 12.7146C16.5803 11.2736 16.3303 10.2596 15.8302 9.67261C15.3433 9.07222 14.5668 8.77203 13.5008 8.77203C12.4216 8.77203 11.632 9.07222 11.1319 9.67261C10.645 10.2596 10.4015 11.2736 10.4015 12.7146C10.4015 14.0354 10.645 15.0094 11.1319 15.6364C11.632 16.2502 12.4216 16.557 13.5008 16.557Z" fill="#312EC6"/>
        </svg>
      }
    </div>

    <button routerLink="/" class="flex gap-1 items-center justify-center text-center bg-[#EC2222] hover:bg-[#EC2222] text-white py-2 px-4 rounded text-[14px] transition-all">
      <mat-icon class="text-sm">logout</mat-icon>
      <span class="font-normal">Выйти</span>
    </button>

  </div>
</div>
